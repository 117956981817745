/*
 * @Description:
 * @Version: 2.0
 * @Author: yichuanhao
 * @Date: 2024-08-14 11:22:01
 * @LastEditors: yichuanhao 1274816963@qq.com
 * @LastEditTime: 2024-09-18 21:41:41
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/tailwind.css';
Vue.use(ElementUI);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import commonDialog from '@/components/commonDialog.vue';
Vue.component('commonDialog', commonDialog);
import NoData from '@/components/NoData/NoData.vue';
Vue.component('NoData', NoData);
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import '@/permission'; // permission control
// highlightjs
import hljs from 'highlight.js';
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';

import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
import 'katex/dist/katex.min.css';
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/npm';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/npm';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
VMdPreview.use(createKatexPlugin());
VMdPreview.use(createHighlightLinesPlugin());
VMdPreview.use(createAlignPlugin());
VMdPreview.use(createCopyCodePlugin());
VMdPreview.use(createLineNumbertPlugin());
VMdPreview.use(createTodoListPlugin());
VMdPreview.use(createMermaidPlugin());
VMdPreview.use(createTipPlugin());
VMdPreview.use(createEmojiPlugin());
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdPreview);
//loading 动画
import vLoading from './components/Loading/loading.js';
Vue.use(vLoading);
const bus = new Vue({
  data() {
    return {};
  },
});
Vue.prototype.$bus = bus; //挂载bus
//全局复写 elementUI 的 message提示
Vue.prototype.$message = function (msg) {
  // msg.showClose = true;
  msg.showClose = true;
  ElementUI.Message(msg);
};
// this.$message.error(res.responseMsg);
Vue.prototype.$message.error = function (msg) {
  ElementUI.Message({
    message: msg,
    showClose: true,
    customClass: 'zZindex',
    type: 'error',
  });
};
Vue.prototype.$message.warning = function (msg) {
  ElementUI.Message({
    message: msg,
    showClose: true,
    customClass: 'zZindex',
    type: 'warning',
  });
};
Vue.prototype.$message.success = function (msg) {
  ElementUI.Message({
    message: msg,
    showClose: true,
    customClass: 'zZindex',
    type: 'success',
  });
};
Vue.prototype.$message.info = function (msg) {
  ElementUI.Message({
    message: msg,
    showClose: true,
    customClass: 'zZindex',
    type: 'info',
  });
};
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
