/*
 * @Description:
 * @Version: 2.0
 * @Author: yichuanhao
 * @Date: 2024-08-16 11:02:27
 * @LastEditors: yichuanhao
 * @LastEditTime: 2024-08-16 11:04:52
 */
const state = {
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null, // 用户登录信息
};

const getters = {};

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
