/*
 * @Author: yichuanhao 1274816963@qq.com
 * @Date: 2024-08-27 22:44:10
 * @LastEditors: yichuanhao
 * @LastEditTime: 2024-09-10 12:22:54
 * @FilePath: \signet\src\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from './router';
router.beforeEach(async (to, from, next) => {
  // if (to.path === '/read') {
  //   next();
  // }
  document.title = '侠梦网';
  next();
});
