<!--
 * @Author: yichuanhao 1274816963@qq.com
 * @Date: 2024-08-19 20:07:15
 * @LastEditors: yichuanhao 1274816963@qq.com
 * @LastEditTime: 2024-08-19 20:37:29
 * @FilePath: \new-project\src\components\NoData\NoData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="common-empty">
    <div class="img-empty-wrap">
      <div class="img-empty"></div>
    </div>
    <div class="content-slot-wrap">
      <div class="no-data">{{ emptyText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoData', // 无数据
  props: {
    emptyText: {
      type: String,
      default: '这里什么都没有',
    },
  },
};
</script>

<style lang="scss">
.common-empty {
  width: 100%;
  user-select: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 0;
  background-color: #fff;
  .img-empty-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    .img-empty {
      display: block;
      width: 180px;
      height: 180px;
      background-size: 100% 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url('./image/nodata.png');
    }
  }
  .no-data {
    font-size: 14px;
    color: #8a919f;
  }
}
</style>
