import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../views/Layout/Layout.vue'),
    children: [
      {
        path: '/entry',
        name: '首页',
        component: () => import('../views/Entry/Entry.vue'),
      },
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/Home/Home.vue'),
      },
      {
        path: '/subject',
        name: '学科',
        component: () => import('../views/Subject/Subject.vue'),
      },
      {
        path: '/read',
        name: '阅读',
        component: () => import('../views/Read/Read.vue'),
      },
      {
        path: '/center',
        name: '个人中心',
        component: () => import('../views/Center/Center.vue'),
      },
      {
        path: '/about',
        name: '关于我们',
        component: () => import('../views/About/About.vue'),
      },
      {
        path: '/contact',
        name: '联系我们',
        component: () => import('../views/Contact/Contact.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;
