<template>
    <div class="loading-wrap" v-if="isShow">
        <div class="loading-box">
        </div>
    </div>
</template>

<script>
import lottie from 'lottie-web'
import * as animationData from './loading.json';
export default {
    name: 'LoadingComponent',
    data() {
        return {
            isShow: false,
        }
    },
    methods: {
        initAnimate(box) {
            // let container = document.querySelector('.loading-box');
            let animate = lottie.loadAnimation({
                container: box,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData.default,
            })
        },
    },
    mounted() {
        
    },
    watch: {
        isShow: function(val) {
            let container;
            val ? (this.$nextTick(() => {
                container = document.querySelector('.loading-box');
                this.initAnimate(container)
            })) : null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .loading-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        z-index: 9999;
    }
    .loading-box {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        width: 200px;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .loading-txt {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #666;
    }
</style>>
