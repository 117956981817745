import LoadingComponent from './loading.vue'

let instance = null;

export default {
    install (Vue, options) {
        if(!instance) {
            let myLoadingComponent = Vue.extend(LoadingComponent)
            instance = new myLoadingComponent({
                el: document.createElement('div')
            })
            document.body.appendChild(instance.$el)
        }
        instance.isShow = false;

        let customMethods = {
            isSpecified:false,
            show() {
                instance.isShow = true
            },
            hide() {
              !this.isSpecified ? instance.isShow = false : null
            }

        }

        if(!Vue.$vLoading) {
            Vue.$vLoading = customMethods;

            Vue.prototype.$vLoading = Vue.$vLoading
        }else {
            //console.log('$vLoading方法已被占用')
        }
    }
}