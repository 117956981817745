<!--
 * @Description:
 * @Version: 2.0
 * @Author: yichuanhao
 * @Date: 2022-06-13 17:29:58
 * @LastEditors: yichuanhao
 * @LastEditTime: 2024-08-16 10:02:30
-->
<template>
  <div class="common__dialog-wrap">
    <el-dialog
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      :width="dialogWidth"
      :title="dialogTitle"
      custom-class="common__dialog"
      @close="dialogClose"
      :show-close="showClose"
      :modal="modal"
    >
      <div slot="title" v-if="!dialogTitle">
        <slot name="title"></slot>
      </div>
      <!-- 内容插槽区域 -->
      <slot></slot>
      <div slot="footer" class="dialog-footer" v-if="isShowFooterBtn">
        <!-- 取消按钮 -->
        <el-button size="small" @click="dialogClose" style="border: none" v-if="isShowCancleBtn">取消</el-button>
        <!-- 其他按钮插槽 -->
        <slot name="footerbtn"></slot>
        <!-- 确定按钮 -->
        <el-button size="small" type="primary" @click="dialogConfirm" v-if="isShowConfirmBtn">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @dialogTitle String 弹窗标题
 * @dialogWidth String 弹窗宽度
 * @isShowCancleBtn Boolean 是否展示取消按钮
 * @isShowConfirmBtn Boolean 是否展示确定按钮
 * @isShowFooterBtn Boolean 是否展示底部按钮
 * @modal Boolean 是否展示遮罩层
 */
export default {
  // 公共弹窗
  name: 'commonDialog',
  props: {
    dialogTitle: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: String,
      default: '400px',
    },
    isShowCancleBtn: {
      type: Boolean,
      default: true,
    },
    isShowConfirmBtn: {
      type: Boolean,
      default: true,
    },
    isShowFooterBtn: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialog: false, // 通过refs获取该实例进行修改，例如：this.$refs.refTitle.showDialog = false;
    };
  },
  methods: {
    dialogClose() {
      this.$emit('dialogClose');
    },
    dialogConfirm() {
      this.$emit('dialogConfirm');
    },
  },
};
</script>

<style lang="scss">
.common__dialog-wrap {
  .el-dialog__wrapper {
    .common__dialog {
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .el-dialog {
      border-radius: 4px;
      width: auto;
      min-width: 100px;
      .el-dialog__header {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 18px;
        border: 1px solid rgba(238, 238, 238, 1);
        .el-dialog__title {
          font-family: Microsoft YaHei;
          font-size: 14px;
          font-weight: bold;
          color: rgba(39, 153, 142, 1);
        }
      }
      .el-dialog__body {
        padding: 30px 58px;
        .el-form {
          .el-input {
            width: 220px;
          }
          .el-form-item__label {
            font-size: 13px;
          }
          .el-form-item {
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
      .el-dialog__footer {
        padding: 0 20px 20px 20px;
        .dialog-footer {
          .el-button--primary {
            width: 76px;
            height: 32px;
            padding: 0;
            border: none;
            font-size: 13px;
          }
          .el-button--default {
            width: 76px;
            height: 32px;
            padding: 0;
            border: none;
            font-size: 13px;
            border: transparent;
            &:hover {
              background: none;
              color: #27998e;
            }
          }
        }
      }
    }
  }
}
</style>
