var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-empty" }, [
    _vm._m(0),
    _c("div", { staticClass: "content-slot-wrap" }, [
      _c("div", { staticClass: "no-data" }, [_vm._v(_vm._s(_vm.emptyText))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-empty-wrap" }, [
      _c("div", { staticClass: "img-empty" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }